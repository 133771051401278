import React, { useState, useEffect, FC } from 'react';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Select, { SelectOption } from '@amzn/meridian/select';
import Button from '@amzn/meridian/button';
import Textarea from '@amzn/meridian/textarea';
import Row from '@amzn/meridian/row';
import { toast } from 'react-toastify';
import { 
    Banner, 
    EMPTY_BANNER, 
    CreateBannerRequest, 
    UpdateBannerRequest
} from '../../../api/models/banner';
import { Tool } from '../../../api/models/tool';
import { adminsEntryPoint } from '../../../api';
import { REQUIRED_INPUT_ERROR_MESSAGE } from '../../../common/constants';

interface BannerModalProps {
    title: string;
    open: boolean;
    isCreateMode: boolean;
    tools: Tool[];
    onClose: () => void;
    onSave: (data: Banner) => void;
    initialData?: Banner;
}

const BannerModal: FC<BannerModalProps> = ({title, open, isCreateMode, tools, onClose, onSave, initialData}) => {
    const [banner, setBanner] = useState<Banner>(initialData || EMPTY_BANNER);
    const [displayError, setDisplayError] = useState<boolean>(false);

    useEffect(() => {
        if (initialData) {
            setBanner(initialData)
        }
    }, [initialData]);

    const handleClose = () => {
        setBanner(EMPTY_BANNER);
        onClose();
        setDisplayError(false);
    }

    const handleSave = () => {
        if (!banner.tool || !banner.message) {
            setDisplayError(true);
            return;
        }

        if (isCreateMode) {
            const createBannerRequest: CreateBannerRequest = {
                api_type: 'CREATE_BANNER',
                api_input: {
                    tool: banner.tool,
                    message: banner.message,
                    banner_type: banner.banner_type,
                    active: banner.active
                }
            }

            const promise = adminsEntryPoint<CreateBannerRequest, any>(createBannerRequest)
                .then((response) => {
                    setBanner(EMPTY_BANNER);
                    onSave(response);
                    onClose();
                    toast.success('Banner Created');
                })
                .catch((error) => {
                    console.error('Error Creating Banner', error);
                    toast.error('Failed to Create Banner');
                })

            return toast.promise(promise, {
                pending: 'Creating Banner...'
            })
        }
        else {
            const updateBannerRequest: UpdateBannerRequest = {
                api_type: 'UPDATE_BANNER',
                api_input: {
                    id: banner.id,
                    tool: banner.tool,
                    message: banner.message,
                    banner_type: banner.banner_type,
                    active: banner.active
                }
            }

            const promise = adminsEntryPoint<UpdateBannerRequest, any>(updateBannerRequest)
                .then((response) => {
                    setBanner(EMPTY_BANNER);
                    onSave(response);
                    onClose();
                    toast.success('Banner Updated');
                })
                .catch((error) => {
                    console.error('Error Updating Banner', error);
                    toast.error('Failed to Update Banner');
                });

            return toast.promise(promise, {
                pending: 'Updating Banner...'
            });
        }
    }

    return (
        <Modal title={title} open={open} onClose={handleClose}>
            <Row widths='grid-12' spacingInset='400'>
                <Select
                    label='Tool'
                    value={banner.tool}
                    onChange={(e) => setBanner({...banner, tool: e})}
                >
                    <SelectOption value="ALL" label="All Tools"></SelectOption>
                    {tools.map((tool) =>
                        <SelectOption value={tool.tool_name} label={tool.tool_name}></SelectOption>
                    )}
                </Select>
            </Row>
            <Row widths='grid-6' spacingInset='400'>
                <Select
                    label='Banner Type'
                    value={banner.banner_type}
                    onChange={(e) => setBanner({...banner, banner_type: e})}
                >
                    <SelectOption value='success' label='Success'/>
                    <SelectOption value='info' label='Information'/>
                    <SelectOption value='warning' label='Warning'/>
                    <SelectOption value='error' label='Error'/>                    
                </Select>
                <Select
                    label='Active'
                    value={banner.active.toString()}
                    onChange={(e) => setBanner({...banner, active: e === 'true' ? true : false})}
                >
                    <SelectOption value='true' label='True'/>
                    <SelectOption value='false' label='False'/>
                </Select>
            </Row>
            <Row widths='grid-12' spacingInset='400'>
                <Textarea
                    label='Banner Message'
                    value={banner.message}
                    onChange={(e) => setBanner({...banner, message: e})}
                    error={!banner.message && displayError}
                    errorMessage={!banner.message ? REQUIRED_INPUT_ERROR_MESSAGE : ''}
                />
            </Row>
            <ModalFooter>
                <Row alignmentHorizontal='end'>
                    <Button type='secondary' onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleSave}>
                        {isCreateMode ? 'Create' : 'Save'}
                    </Button>
                </Row>
            </ModalFooter>
        </Modal>
    )
}

export default BannerModal;