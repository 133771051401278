import { APIType } from "../types";

export interface Shift {
    id: number;
    warehouse_id: string;
    shift_name: string;
    active: boolean;
}

interface CreateShiftInput {
    warehouse_id: string;
    shift_name: string;
    active: boolean;
}

interface DeleteShiftInput {
    id: number;
}

export interface CreateShiftRequest {
    api_type: APIType;
    api_input: CreateShiftInput;
}

export interface UpdateShiftRequest {
    api_type: APIType;
    api_input: Shift;
}

export interface DeleteShiftRequest {
    api_type: APIType;
    api_input: DeleteShiftInput;
}

export const EMPTY_SHIFT: Shift = {
    id: 1,
    warehouse_id: '',
    shift_name: '',
    active: false
}