import React, { useState } from 'react';
import Table, { TableRow, TableCell, TableActionBar } from "@amzn/meridian/table"
import { TableSortDirection } from '@amzn/meridian/table/table';
import Pagination from '@amzn/meridian/pagination';
import Input from '@amzn/meridian/input';
import Loader from '@amzn/meridian/loader';
import { SortValues } from '../../api/models/tables'

interface TablesProps {
    headers: string[];
    data: (string | number | boolean | React.ReactNode)[][];
    isLoading?: boolean;
    column?: string;
    direction?: TableSortDirection;
    itemsPerPage?: number;
    isSearchable?: boolean;
}

const Tables: React.FC<TablesProps> = (props) => {
    const { headers, data, isLoading, column, direction, itemsPerPage = 10, isSearchable = true } = props

    // State for searching
    const [searchTerm, setSeachTerm] = useState<string>("")

    // State for sorting and logic
    const [sortColumn, setSortColumn] = useState<string | undefined>(column)
    const [sortDirection, setSortDirection] = useState<TableSortDirection | undefined>(direction)

    // Sorting logic and filtering data bases on search
    const sortedData = [...data]
    sortedData.sort((a, b) => {
        const columnIndex = headers.indexOf(sortColumn || "")
        const valueA = a[columnIndex] ?? 0;
        const valueB = b[columnIndex] ?? 0;

        if (valueA < valueB) {
            return sortDirection === "ascending" ? -1 : 1;
        }
        if (valueA > valueB) {
            return sortDirection === "ascending" ? 1 : -1;
        }
        return 0;
    })

    const filteredData = sortedData.filter(row => {
        const rowDataString = row.join(' ').toLowerCase();
        return rowDataString.includes(searchTerm.toLowerCase())
    })

    // Pagination logic
    const [currentPage, setCurrentPage] = useState(1);
    const firstVisibleIndex = (currentPage -1) * itemsPerPage;
    const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
    const totalNumberOfPages = Math.ceil(filteredData.length / itemsPerPage);

    const onSort = (sort: SortValues) => {
        setSortDirection(sort.sortDirection)
        setSortColumn(sort.sortColumn)
    }

    const onPageChange = (newPage: number) => {
        setCurrentPage(newPage);
    }

    const onSearchChange = (value: string) => {
        setSeachTerm(value)
        setCurrentPage(1);
    }

    return (
        isLoading ? 
            <div style={{ textAlign: 'center' }}>
                <Loader type='circular'/>
            </div>
            :
            <>
                <Table 
                    headerRows={1} 
                    showDividers={true} 
                    showStripes={true} 
                    spacing="small" 
                    sortColumn={sortColumn} 
                    sortDirection={sortDirection} 
                    onSort={onSort}
                >
                    {isSearchable
                        ?
                            <TableActionBar>
                                <Input
                                    type='text'
                                    placeholder='Search...'
                                    value={searchTerm}
                                    onChange={onSearchChange}
                                />
                            </TableActionBar>
                        :
                            null
                    }
                    <TableRow>
                        {headers.map((header, index) => {
                            const sortHeaders = column && direction && header !== "Actions" && header !== "Input";
                            return (
                                <TableCell
                                    key={index}
                                    alignmentHorizontal='center'
                                    sortColumn={sortHeaders ? header : ""}
                                >
                                    {header}
                                </TableCell>
                            )
                        })}
                    </TableRow>
                    {filteredData
                        .slice(firstVisibleIndex, lastVisibleIndex)
                        .map((rowData, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {rowData.map((cell, cellIndex) => (
                                    <TableCell key={cellIndex} alignmentHorizontal='center'>
                                        {cell}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    }
                </Table>
                {totalNumberOfPages > 1 && (
                    <Pagination 
                        showSkipArrows={true}
                        numberOfPages={totalNumberOfPages}
                        onChange={onPageChange}
                        currentPage={currentPage}
                    />
                )}
            </>
    )
}

export default Tables;