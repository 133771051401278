import { APIType } from "../types";

export interface Process {
    id: number;
    warehouse_id: string;
    process_name: string;
    short_name: string;
    department: string;
    function_type: string;
    active: boolean;
}

interface CreateProcessInput {
    warehouse_id: string;
    process_name: string;
    short_name: string;
    department: string;
    function_type: string;
    active: boolean;
}

interface DeleteProcessInput {
    id: number;
}

export interface CreateProcessRequest {
    api_type: APIType;
    api_input: CreateProcessInput;
}

export interface UpdateProcessRequest {
    api_type: APIType;
    api_input: Process;
}

export interface DeleteProcessRequest {
    api_type: APIType;
    api_input: DeleteProcessInput;
}

export const EMPTY_PROCESS: Process = {
    id: 1,
    warehouse_id: '',
    process_name: '',
    short_name: '',
    department: '',
    function_type: '',
    active: false
}