import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { axiosErrorToastMessage } from '../common/utilities';
import { NOT_AUTHORIZED } from '../common/constants';

export function adminsEntryPoint<In, Out>(params: In): Promise<Out> {
    return new Promise((resolve, reject) => {
        axios.post("/api/entry_point", params)
            .then((response: AxiosResponse<Out>) => resolve(response.data))
            .catch((error) => {
                const errors: string[] = error.response.data.errors;
                if (errors && errors.includes(NOT_AUTHORIZED)) {
                    console.log("Redirecting Unauthorized User");
                    window.location.href = `https://${window.location.href.split("/")[2]}/unauthorized`;
                }
                else if (errors) {
                    toast.error(axiosErrorToastMessage(errors));
                }
                else {
                    toast.error(axiosErrorToastMessage([]));
                }
                reject(error);
            });
    });
}