import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Masthead, { MastheadTitle, MastheadLink } from '@amzn/meridian/masthead';
import Text from '@amzn/meridian/text';

const NavBar = () => {
    let location = useLocation().pathname
    let navigate = useNavigate()
    const [selected, setSelected] = useState<string>(location)
    const onClickLink = (id: string) => () => {
        setSelected(id)
        navigate(id)
    }

    return (
        <Masthead>
            
            <MastheadTitle onClick={onClickLink("/")}>
                <Text fontFamily='bookerly' type='b500'>
                    Admins Portal
                </Text>
            </MastheadTitle>
            <MastheadLink selected={selected === "/"} onClick={onClickLink("/")}>
                Data Portal
            </MastheadLink>
            <MastheadLink selected={selected === "/banners"} onClick={onClickLink("/banners")}>
                Banners
            </MastheadLink>
            <MastheadLink selected={selected === "/logs"} onClick={onClickLink("/logs")}>
                Logs
            </MastheadLink>
        </Masthead>
    )
}

export default NavBar;