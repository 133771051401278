import React, { forwardRef, useState, useRef, useImperativeHandle } from 'react';
import Column from '@amzn/meridian/column';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import menuMeatballTokens from "@amzn/meridian-tokens/base/icon/menu-meatball"
import Popover from '@amzn/meridian/popover';

interface TablePopoverProps {
    onEdit: () => void;
    onDelete?: () => void;
    isDisabled?: boolean;
}

const TablePopover = forwardRef((props: TablePopoverProps, ref) => {
    const [buttonPopoverOpen, setButtonPopoverOpen] = useState(false)
    
    const onOpenPopover = () => setButtonPopoverOpen(true);
    const onClosePopover = () => setButtonPopoverOpen(false);

    const buttonRef = useRef<HTMLButtonElement | undefined>(undefined)

    useImperativeHandle(ref, () => ({
        openPopover: () => {
            onOpenPopover();
        },
        closePopover: () => {
            onClosePopover();
        }
    }))

    const handleEditClick = () => {
        if (props.onEdit) {
            props.onEdit()
        }
    }

    const handleDeleteClick = () => {
        if (props.onDelete) {
            props.onDelete()
        }
    }

    return (
        <React.Fragment>
            <Button type='icon' ref={buttonRef} onClick={onOpenPopover}>
                <Icon tokens={menuMeatballTokens}>Action</Icon>
            </Button>
            <Popover
                position='end'
                anchorNode={buttonRef.current}
                open={buttonPopoverOpen}
                onClose={onClosePopover}
            >
                <Column spacing='300'>
                    <Button type='secondary' onClick={handleEditClick}>Edit Row</Button>
                    <Button type='primary' onClick={handleDeleteClick} disabled={props.isDisabled}>Delete Row</Button>
                </Column>
            </Popover>
        </React.Fragment>
    )
})

export default TablePopover;
