export const MISSING_REQUIRED_PARAMETER: string = "MISSING_REQUIRED_PARAMETER"
export const INVALID_API: string = "INVALID_API"
export const MISSING_RECORD: string = "RECORD_NOT_FOUND"
export const NOT_AUTHORIZED: string = "USER_NOT_AUTHORIZED"
export const RDS_ATTRIBUTE_ERROR: string = "RDS_ATTRIBUTE_ERROR"
export const POSIX_GROUP_KEY: string = "custom:POSIX_GROUP";
export const DEPT_NAME: string = "custom:AMZN_DEPT_NAME";
export const LOCATION: string = "custom:AMZN_LOC_DESCR";
export const REQUIRED_INPUT_ERROR_MESSAGE: string = "This field is required";

// Table Headers
export const WAREHOUSE_HEADER_ROW = [
    'Warehouse',
    'TimeZone',
    'UTC Offset',
    'Colocated Site',
    'Country',
    'Operations Manager',
    'Actions'
];
export const SHIFT_HEADER_ROW = [
    "Warehouse",
    "Shift",
    "Active",
    "Actions"
];
export const DEPARTMENT_HEADER_ROW = [
    "Warehouse",
    "Department Name",
    "Short Name",
    "Flow Path",
    "Active",
    "Actions"
];
export const PROCESSES_HEADER_ROW = [
    "Warehouse",
    "Process Name",
    "Short Name",
    "Department",
    "Function-Type",
    "Active",
    "Actions"
];