import React, { ReactElement, useEffect, useState, useRef, useCallback } from 'react';
import Card from '@amzn/meridian/card';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';

import Tables from '../components/features/tables';
import TablePopover from '../components/features/tablePopover';
import BannerModal from '../components/features/modals/bannersModal';
import { adminsEntryPoint } from '../api';
import {
    Banner,
    GetAllBannersRequest,
    GetAllBannersResponse,
    EMPTY_BANNER,
    DeleteBannerRequest,
} from '../api/models/banner';
import { Tool } from '../api/models/tool';
import { toast } from 'react-toastify';

const Banners = (): ReactElement => {
    const bannerRef = useRef()
    const [banners, setBanners] = useState<Banner[]>([]);
    const [tools, setTools] = useState<Tool[]>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalData, setModalData] = useState<Banner>(EMPTY_BANNER);
    const [isCreateMode, setIsCreateMode] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const fetchData = useCallback(() => {
        setIsLoading(true)
        adminsEntryPoint<GetAllBannersRequest, GetAllBannersResponse>({
            api_type: 'GET_ALL_BANNERS'
        }).then((response: GetAllBannersResponse) => {
            setBanners(response.banners);
            setTools(response.tools);
            setIsLoading(false)
        }).catch(error => {
            console.error(error);
        });
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const TABLE_HEADER_ROW: string[] = [
        'Tool',
        'Type',
        'Active',
        'Message',
        'Actions'
    ]

    const openModal = (): void => {
        setModalData(EMPTY_BANNER);
        setIsCreateMode(true);
        setModalOpen(true);
    }

    const handleEditRow = (rowData: Banner): void => {
        setModalData(rowData);
        setIsCreateMode(false);
        setModalOpen(true);
    }

    const handleDeleteRow = (rowData: Banner): Promise<void> => {
        const promise = adminsEntryPoint<DeleteBannerRequest, null>(
            {
                api_type: 'DELETE_BANNER',
                api_input: {id: rowData.id}
            }
        ).then(() => {
            setModalData(EMPTY_BANNER);
            fetchData();
            toast.success('Banner Deleted');
        }).catch((error) => {
            console.error('Error Deleting Banner', error);
            toast.error('Failed to Delete Banner');
        });

        return toast.promise(promise, {
            pending: 'Deleting Banner...'
        });
    }

    const TABLE_DATA = banners.map((banner) => [
        banner.tool || '',
        banner.banner_type || '',
        <span style={{ color: banner.active ? 'green' : 'red' }}>
            {banner.active ? 'true' : 'false'}
        </span>,
        banner.message || '',
        <TablePopover 
            ref={bannerRef} 
            onEdit={() => handleEditRow(banner)}
            onDelete={() => handleDeleteRow(banner)}
        />
    ]);

    return (
        <Column spacingInset='400' alignmentHorizontal='center'>
            <Card spacingInset='none' width='90%'>
                <Box spacingInset='400'>
                    <Text type='d200' alignment='center'>
                        Tool Banners
                    </Text>
                    <Button onClick={openModal}>
                        New Banner
                    </Button>
                </Box>
                <Tables
                    headers={TABLE_HEADER_ROW}
                    data={TABLE_DATA}
                    isLoading={isLoading}
                    column={"Tool"}
                    direction={"ascending"}
                />
            </Card>
            <BannerModal
                title={isCreateMode ? 'Create Banner' : 'Edit Banner'}
                open={modalOpen}
                isCreateMode={isCreateMode}
                tools={tools}
                onSave={() => {
                    fetchData();
                    setModalOpen(false);
                }}
                onClose={() => setModalOpen(false)}
                initialData={modalData}
            />
        </Column>
    )
}

export default Banners;