import { APIType } from "../types";
import { Warehouse } from "./warehouse";
import { Region } from "./region";
import { Department } from "./department";
import { Shift } from "./shift";
import { Process } from "./process";

interface GetAllFCDataInput {
    warehouse_id: string;
}

export interface GetAllFCDataRequest {
    api_type: APIType;
    api_input: GetAllFCDataInput;
}

export interface GetAllFCDataResponse {
    warehouses: Warehouse[];
    regions: Region[];
    departments: Department[];
    shifts: Shift[];
    processes: Process[];
}

export const EMPTY_GET_ALL_FC_DATA_RESPONSE: GetAllFCDataResponse = {
    warehouses: [],
    regions: [],
    departments: [],
    shifts: [],
    processes: []
}