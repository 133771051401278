import React from 'react';
import Masthead, { MastheadTitle } from '@amzn/meridian/masthead';
import Button from '@amzn/meridian/button';

const Footer = () => {
    
    return (
        <Masthead>
            <MastheadTitle>
                <Button type='primary' size='small' href='https://w.amazon.com/bin/view/CHACES' target='_blank'>
                    Built by ReSET
                </Button>
            </MastheadTitle>
        </Masthead>
    )
}

export default Footer;