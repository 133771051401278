import {
    MISSING_REQUIRED_PARAMETER,
    INVALID_API,
    MISSING_RECORD,
    RDS_ATTRIBUTE_ERROR,
    NOT_AUTHORIZED
} from "./constants";

/**
 * Safe parser for JSON to handle failed parsing
 */
export function JSONParse<T>(jsonString: string, defaultValue?: T) : T {
    try {
        return JSON.parse(jsonString) as T;
    } catch(e) {
        console.log(e);
        return defaultValue ? defaultValue : false as T;
    }
}

export function epochToDate(epoch: number): string {
    return (new Date(epoch)).toLocaleString();
}

export function axiosErrorToastMessage(errors: string[]): string {
    if (errors.includes(MISSING_REQUIRED_PARAMETER)) {
        return "The request is missing a required parameter";
    }
    else if (errors.includes(INVALID_API)) {
        return "The API requested was not found";
    }
    else if (errors.includes(MISSING_RECORD)) {
        return "The record(s) requested were not found";
    }
    else if (errors.includes(RDS_ATTRIBUTE_ERROR)) {
        return "There was an issue with the request";
    }
    else if (errors.includes(NOT_AUTHORIZED)) {
        return "You are not authorized to make this request";
    }
    return "Oops Something Went Wrong";
}