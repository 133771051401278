import { APIType } from "../types";

export interface Warehouse {
    id: number;
    warehouse_id: string;
    timezone: string;
    utc_offset: number;
    has_linked_warehouses: boolean;
    linked_warehouses: string;
    country: string;
    operations_manager: string;
}

interface CreateWarehouseInput {
    warehouse_id: string;
    timezone: string;
    utc_offset: number;
    has_linked_warehouses: boolean;
    linked_warehouses: string;
    country: string;
    operations_manager: string;
}

export interface CreateWarehouseRequest {
    api_type: APIType;
    api_input: CreateWarehouseInput;
}

export interface UpdateWarehouseRequest {
    api_type: APIType;
    api_input: Warehouse;
}

export const EMPTY_WAREHOUSE: Warehouse = {
    id: 1,
    warehouse_id: '',
    timezone: '',
    utc_offset: -5,
    has_linked_warehouses: false,
    linked_warehouses: '',
    country: '',
    operations_manager: ''
}