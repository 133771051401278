import React, { useState, useEffect, useContext, FC } from 'react';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Select, { SelectOption } from '@amzn/meridian/select';
import Button from '@amzn/meridian/button';
import Input from '@amzn/meridian/input';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import { toast } from 'react-toastify';
import { 
    Department, 
    EMPTY_DEPARTMENT, 
    CreateDepartmentRequest, 
    UpdateDepartmentRequest,
    DeleteDepartmentRequest
} from '../../../api/models/department';
import { adminsEntryPoint } from '../../../api';
import { AuthContext } from '../../../auth/authContext';
import { REQUIRED_INPUT_ERROR_MESSAGE } from '../../../common/constants';

interface DepartmentModalProps {
    title: string;
    open: boolean;
    onClose: () => void;
    initialData: Department;
    onSave: (data: Department) => void;
    isCreateMode: boolean;
    isDeleteMode?: boolean;
}

const DepartmentModal: FC<DepartmentModalProps> = (props) => {
    const { title, open, onClose, initialData, onSave, isCreateMode, isDeleteMode } = props
    const [department, setDepartment] = useState<Department>(initialData || EMPTY_DEPARTMENT)
    const [displayError, setDisplayError] = useState<boolean>(false);
    const { location } = useContext(AuthContext)

    useEffect(() => {
        if (initialData) {
            setDepartment(initialData)
        }
    }, [initialData])

    const handleClose = () => {
        setDepartment(initialData)
        onClose()
        setDisplayError(false);
    }

    const handleSave = () => {
        if (department.department_name === "") {
            setDisplayError(true);
            return;
        }

        if (isCreateMode) {
            const createDepartmentRequest: CreateDepartmentRequest = {
                api_type: 'CREATE_DEPARTMENT',
                api_input: {
                    warehouse_id: location,
                    department_name: department.department_name,
                    short_name: department.short_name,
                    flow_path: department.flow_path,
                    active: department.active
                }
            }

            const promise = adminsEntryPoint<CreateDepartmentRequest, any>(createDepartmentRequest)
                .then((response) => {
                    setDepartment(EMPTY_DEPARTMENT)
                    onSave(response)
                    onClose()
                    toast.success('Department Created')
                })
                .catch((error) => {
                    console.error('Error Creating Department', error);
                    toast.error('Failed to Create Department')
                })

            return toast.promise(promise, {
                pending: 'Creating Department...'
            })
        } else {
            const updateDepartmentRequest: UpdateDepartmentRequest = {
                api_type: 'UPDATE_DEPARTMENT',
                api_input: {
                    id: department.id,
                    warehouse_id: department.warehouse_id,
                    department_name: department.department_name,
                    short_name: department.short_name,
                    flow_path: department.flow_path,
                    active: department.active
                }
            }

            const promise = adminsEntryPoint<UpdateDepartmentRequest, any>(updateDepartmentRequest)
                .then((response) => {
                    setDepartment(EMPTY_DEPARTMENT)
                    onSave(response)
                    onClose()
                    toast.success('Department Updated')
                })
                .catch((error) => {
                    console.error('Error Updating Department:', error)
                    toast.error('Failed to Update Department')
                })

            return toast.promise(promise, {
                pending: 'Updating Department...'
            })
        }
    }

    const handleDelete = () => {
        const deleteDepartmentRequest: DeleteDepartmentRequest = {
            api_type: 'DELETE_DEPARTMENT',
            api_input: {
                id: department.id
            }
        }

        const promise = adminsEntryPoint<DeleteDepartmentRequest, any>(deleteDepartmentRequest)
            .then((response) => {
                onSave(response)
                onClose()
                toast.success('Department Deleted')
            })
            .catch((error) => {
                console.error('Error Deleting Department:', error)
                toast.error('Failed to Delete Department')
            })

        return toast.promise(promise, {
            pending: 'Deleting Department...'
        })
    }

    return (
        <>
            {isDeleteMode ? (
                <Modal title='Delete Department' open={open} onClose={handleClose}>
                    <Text type='b500'>
                        Are you sure you want to delete <b>{department.department_name}</b>?
                    </Text>
                    <ModalFooter>
                        <Row alignmentHorizontal='end'>
                            <Button type='secondary' onClick={handleClose}>No</Button>
                            <Button onClick={handleDelete}>Yes</Button>
                        </Row>
                    </ModalFooter>
                </Modal>
            ) : (
                <Modal title={title} open={open} onClose={handleClose} onSubmitForm={handleSave}>
                    <Row widths="grid-6" spacingInset="400">
                        <Input 
                            label='Warehouse'
                            value={location}
                            disabled={true}
                            onChange={() => setDepartment({...department, warehouse_id: location})}
                        />
                        <Input 
                            inaccessibleAutoFocus
                            label='Department Name'
                            value={department.department_name}
                            onChange={(e) => setDepartment({...department, department_name: e})}
                            error={!department.department_name && displayError}
                            errorMessage={!department.department_name ? REQUIRED_INPUT_ERROR_MESSAGE : ''}
                        />
                    </Row>
                    <Row widths="grid-4" spacingInset="400">
                        <Input 
                            label='Short Name'
                            value={department.short_name}
                            onChange={(e) => setDepartment({...department, short_name: e})}
                        />
                        <Input 
                            label='Flow Path'
                            value={department.flow_path}
                            onChange={(e) => setDepartment({...department, flow_path: e})}
                        />
                        <Select
                            label='Active'
                            value={department.active.toString()}
                            onChange={(e) => setDepartment({...department, active: e === 'true' ? true : false})}
                        >
                            <SelectOption value='true' label='True'/>
                            <SelectOption value='false' label='False'/>
                        </Select>
                    </Row>
                    <ModalFooter>
                        <Row alignmentHorizontal='end'>
                            <Button type='secondary' onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button submit={true}>
                                {isCreateMode ? 'Create' : 'Save'}
                            </Button>
                        </Row>
                    </ModalFooter>
                </Modal>
            )}
        </>
    )
}

export default DepartmentModal;