import React, { useState, useEffect, FC } from 'react';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Button from '@amzn/meridian/button';
import Input from '@amzn/meridian/input';
import Row from '@amzn/meridian/row';
import { toast } from 'react-toastify';
import { 
    Warehouse, 
    EMPTY_WAREHOUSE, 
    UpdateWarehouseRequest, 
    CreateWarehouseRequest 
} from '../../../api/models/warehouse';
import { adminsEntryPoint } from '../../../api';
import { REQUIRED_INPUT_ERROR_MESSAGE } from '../../../common/constants';

interface WarehouseModalProps {
    title: string;
    open: boolean;
    onClose: () => void;
    initialData: Warehouse;
    onSave: (data: Warehouse) => void;
    isCreateMode: boolean;
}

const WarehouseModal: FC<WarehouseModalProps> = ({ title, open, onClose, initialData, onSave, isCreateMode }) => {
    const [warehouse, setWarehouse] = useState<Warehouse>(EMPTY_WAREHOUSE)
    const [displayError, setDisplayError] = useState<boolean>(false);

    useEffect(() => {
        if (initialData) {
            setWarehouse(initialData)
        }
    }, [initialData])

    const handleClose = () => {
        setWarehouse(initialData)
        onClose()
    }

    const handleSave = () => {
        if (
            warehouse.warehouse_id === "" ||
            warehouse.timezone === "" ||
            warehouse.country === "" ||
            warehouse.operations_manager === ""
            ) {
            setDisplayError(true);
            return;
        }

        // Set has_linked_warehouses based on linked_warehouses
        const hasLinkedWarehouses = Boolean(warehouse.linked_warehouses);

        const createWarehouseRequest: CreateWarehouseRequest = {
            api_type: 'CREATE_WAREHOUSE',
            api_input: {
                warehouse_id: warehouse.warehouse_id,
                timezone: warehouse.timezone,
                utc_offset: Number(warehouse.utc_offset),
                has_linked_warehouses: hasLinkedWarehouses,
                linked_warehouses: warehouse.linked_warehouses,
                country: warehouse.country,
                operations_manager: warehouse.operations_manager
            }
        }

        const updateWarehouseRequest: UpdateWarehouseRequest = {
            api_type: 'UPDATE_WAREHOUSE',
            api_input: {
                id: warehouse.id,
                warehouse_id: warehouse.warehouse_id,
                timezone: warehouse.timezone,
                utc_offset: Number(warehouse.utc_offset),
                has_linked_warehouses: hasLinkedWarehouses,
                linked_warehouses: warehouse.linked_warehouses,
                country: warehouse.country,
                operations_manager: warehouse.operations_manager
            }
        }

        const promise = isCreateMode
            ? adminsEntryPoint<CreateWarehouseRequest, any>(createWarehouseRequest)
            : adminsEntryPoint<UpdateWarehouseRequest, any>(updateWarehouseRequest);
        promise
            .then((response) => {
                setWarehouse(EMPTY_WAREHOUSE);
                onSave(response);
                onClose();
                toast.success(isCreateMode ? 'Warehouse Created' : 'Warehouse Updated');
                setDisplayError(false);
            })
            .catch((error) => {
                console.error(`Error ${isCreateMode ? 'Creating' : 'Updating'} Warehouse:`, error);
                toast.error(`Failed to ${isCreateMode ? 'Create' : 'Update'} Warehouse`)
            });

        return toast.promise(promise, {
            pending: `${isCreateMode ? 'Creating' : 'Updating'} Warehouse...`,
        });
    }

    return (
        <Modal title={title} open={open} onClose={handleClose} onSubmitForm={handleSave}>
            <Row alignmentHorizontal='center'>
                <Input 
                    label='Warehouse'
                    value={warehouse.warehouse_id}
                    onChange={(e) => setWarehouse({...warehouse, warehouse_id: e})}
                    disabled={isCreateMode ? false : true}
                    error={!warehouse.warehouse_id && displayError}
                    errorMessage={!warehouse.warehouse_id ? REQUIRED_INPUT_ERROR_MESSAGE : ''}
                    required
                />
                <Input 
                    label='TimeZone'
                    value={warehouse.timezone}
                    onChange={(e) => setWarehouse({...warehouse, timezone: e})}
                    error={!warehouse.timezone && displayError}
                    errorMessage={!warehouse.timezone ? REQUIRED_INPUT_ERROR_MESSAGE : ''}
                    required
                />
                <Input 
                    label='UTC Offset'
                    value={warehouse.utc_offset.toString()}
                    onChange={(e) => setWarehouse({...warehouse, utc_offset: e})}
                />
            </Row>
            <Row alignmentHorizontal='center' spacingInset='400'>
                <Input 
                    label='Colocated Site'
                    value={warehouse.linked_warehouses}
                    onChange={(e) => setWarehouse({...warehouse, linked_warehouses: e})}
                />
                <Input 
                    label='Country'
                    value={warehouse.country}
                    onChange={(e) => setWarehouse({...warehouse, country: e})}
                    error={!warehouse.country && displayError}
                    errorMessage={!warehouse.country ? REQUIRED_INPUT_ERROR_MESSAGE : ''}
                    required
                />
            </Row>
            <Row alignmentHorizontal='center'>
            <Input
                label='Operations Manager'
                value={warehouse.operations_manager}
                onChange={(e) => setWarehouse({...warehouse, operations_manager: e})}
                error={!warehouse.operations_manager && displayError}
                errorMessage={!warehouse.operations_manager ? REQUIRED_INPUT_ERROR_MESSAGE : ''}
                required
            />
            </Row>
            <ModalFooter>
                <Row alignmentHorizontal='end'>
                    <Button type='secondary' onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button submit={true}>
                        {isCreateMode ? 'Create' : 'Save'}
                    </Button>
                </Row>
            </ModalFooter>
        </Modal>
    )
}

export default WarehouseModal;