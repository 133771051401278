import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import mockServer from './mockServer/mockServer'

if (process.env.NODE_ENV === 'development') {
    mockServer();
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(<App />);

reportWebVitals();
