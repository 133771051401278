import React, { ReactElement, useEffect, useState } from 'react';
import Card from '@amzn/meridian/card';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Box from '@amzn/meridian/box';
import Tables from '../components/features/tables';
import Button from '@amzn/meridian/button';
import Modal from "@amzn/meridian/modal";

import { adminsEntryPoint } from '../api';
import { epochToDate } from '../common/utilities';
import { GetAllAccessLogsRequest, GetAllAccessLogsResponse, EMPTY_GET_ALL_ACCESS_LOGS_RESPONSE } from '../api/models/accessLog';

const AccessLogs = (): ReactElement => {
    const [accessLogs, setAccessLogs] = useState<GetAllAccessLogsResponse>(EMPTY_GET_ALL_ACCESS_LOGS_RESPONSE);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalData, setModalData] = useState<{[key: string]: any}>({});
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        setIsLoading(true)
        adminsEntryPoint<GetAllAccessLogsRequest, GetAllAccessLogsResponse>({
            api_type: 'GET_ALL_ACCESS_LOGS'
        }).then((response: GetAllAccessLogsResponse) => {
            setAccessLogs(response);
            setIsLoading(false)
        }).catch(error => {
            console.error(error);
        });
    }, []);

    const openInputModal = (apiInput: {[key: string]: any}): void => {
        setModalData(apiInput);
        setModalOpen(true);
    }

    const closeModal = () => setModalOpen(false);

    const TABLE_HEADER_ROW: string[] = [
        'API',
        'Input',
        'Login',
        'Result',
        'Error',
        'Timestamp'
    ]

    const TABLE_DATA = accessLogs.logs.map((log) => [
        log.api_type || '',
        (
            <Button
                type='primary'
                onClick={() => openInputModal(log.api_input)}
            >
                View Input
            </Button>
        ),
        log.login || '',
        log.result || '',
        log.error_code || 'N/A',
        epochToDate(log.created_at) || ''
    ]);

    return (
        <Column spacingInset='400' alignmentHorizontal='center'>
            <Card spacingInset='none' width='90%'>
                <Box spacingInset='400'>
                    <Text type='d200' alignment='center'>
                        Access Logs
                    </Text>
                </Box>
                <Tables
                    headers={TABLE_HEADER_ROW}
                    data={TABLE_DATA}
                    isLoading={isLoading}
                    column={"Login"}
                    direction={"ascending"}
                />
            </Card>
            <Modal
                title='API Input'
                open={modalOpen}
                onClose={closeModal}
            >
                <Tables
                    headers={Object.keys(modalData)}
                    data={[Object.values(modalData).map((data) => [data.toString()])]}
                    isSearchable={false}
                />
            </Modal>
        </Column>
    )
}

export default AccessLogs;