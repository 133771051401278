import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppLayout from '@amzn/meridian/app-layout';
import NavBar from './components/layout/navBar';
import Footer from './components/layout/footer';
import AccessLogs from './pages/accessLogs';
import Banners from './pages/banners';
import AuthorizationWrapper from './auth/authContext';
import FcData from './pages/fcData';
import Unauthorized from './pages/unauthorized';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {

    return (
        <AuthorizationWrapper>
            <Router>
                <AppLayout
                    headerComponent={NavBar}
                    footerComponent={Footer}
                >
                    <NavBar />
                    <Footer />
                    <Routes>
                        <Route path="/" element={<FcData />} />
                        <Route path="/logs" element={<AccessLogs/>}/>
                        <Route path="/banners" element={<Banners/>}/>
                        <Route path="/unauthorized" element={<Unauthorized/>}/>
                    </Routes>
                </AppLayout>
            </Router>
            <ToastContainer 
                position='bottom-left'
                draggable
                pauseOnHover
                theme='dark'
            />
        </AuthorizationWrapper>
    )
}

export default App;
