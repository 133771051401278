import { APIType } from "../types";

export interface Department {
    id: number;
    warehouse_id: string;
    department_name: string;
    short_name: string;
    flow_path: string;
    active: boolean;
}

interface CreateDepartmentInput {
    warehouse_id: string;
    department_name: string;
    short_name: string;
    flow_path: string;
    active: boolean;
}

interface DeleteDepartmentInput {
    id: number;
}

export interface CreateDepartmentRequest {
    api_type: APIType;
    api_input: CreateDepartmentInput;
}

export interface UpdateDepartmentRequest {
    api_type: APIType;
    api_input: Department;
}

export interface DeleteDepartmentRequest {
    api_type: APIType;
    api_input: DeleteDepartmentInput;
}

export const EMPTY_DEPARTMENT: Department = {
    id: 1,
    warehouse_id: '',
    department_name: '',
    short_name: '',
    flow_path: '',
    active: false
}