import React from 'react';
import UnAuthorizedGraphic from '../images/UnAuthorized.png'

const Unauthorized = () => {

    return (
        <div style={{textAlign: 'center'}}>
            <img 
                src={UnAuthorizedGraphic}
                alt='Unauthorized'
            />
        </div>
    )
}

export default Unauthorized;