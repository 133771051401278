import React, { useState, useEffect, useContext, FC } from 'react';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Select, { SelectOption } from '@amzn/meridian/select';
import Button from '@amzn/meridian/button';
import Input from '@amzn/meridian/input';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import { toast } from 'react-toastify';
import { Shift, EMPTY_SHIFT, CreateShiftRequest, UpdateShiftRequest, DeleteShiftRequest } from '../../../api/models/shift';
import { adminsEntryPoint } from '../../../api';
import { AuthContext } from '../../../auth/authContext';
import { REQUIRED_INPUT_ERROR_MESSAGE } from '../../../common/constants';

interface ShiftModalProps {
    title: string;
    open: boolean;
    onClose: () => void;
    initialData: Shift;
    onSave: (data: Shift) => void;
    isCreateMode: boolean;
    isDeleteMode?: boolean;
}

const ShiftModal: FC<ShiftModalProps> = ({ title, open, onClose, initialData, onSave, isCreateMode, isDeleteMode }) => {
    const [shift, setShift] = useState<Shift>(initialData || EMPTY_SHIFT)
    const [displayError, setDisplayError] = useState<boolean>(false);
    const { location } = useContext(AuthContext)

    useEffect(() => {
        if (initialData) {
            setShift(initialData)
        }
    }, [initialData])

    const handleClose = () => {
        setShift(initialData)
        onClose()
        setDisplayError(false);
    }

    const handleSave = () => {
        if (shift.shift_name === "") {
            setDisplayError(true);
            return;
        }

        if (isCreateMode) {
            const createShiftRequest: CreateShiftRequest = {
                api_type: 'CREATE_SHIFT',
                api_input: {
                    warehouse_id: location,
                    shift_name: shift.shift_name,
                    active: shift.active
                }
            }
            
            const promise = adminsEntryPoint<CreateShiftRequest, any>(createShiftRequest)
                .then((response) => {
                    setShift(EMPTY_SHIFT)
                    onSave(response)
                    onClose();
                    toast.success('Shift Created')
                })
                .catch((error) => {
                    console.error('Error Creating Shift:', error);
                    toast.error('Failed to Create Shift')
                })

            return toast.promise(promise, {
                pending: 'Creating Shift...'
            })
        } else {
            const updateShiftRequest: UpdateShiftRequest = {
                api_type: 'UPDATE_SHIFT',
                api_input: {
                    id: shift.id,
                    warehouse_id: shift.warehouse_id,
                    shift_name: shift.shift_name,
                    active: shift.active
                }
            }
            
            const promise = adminsEntryPoint<UpdateShiftRequest, any>(updateShiftRequest)
                .then((response) => {
                    setShift(EMPTY_SHIFT)
                    onSave(response)
                    onClose();
                    toast.success('Shift Updated')
                })
                .catch((error) => {
                    console.error('Error Updating Shift:', error)
                    toast.error('Failed to Update Shift')
                });
            
            return toast.promise(promise, {
                pending: 'Updating Shift...'
            })
        }
    }

    const handleDelete = () => {
        const deleteShiftRequest: DeleteShiftRequest = {
            api_type: 'DELETE_SHIFT',
            api_input: {
                id: shift.id
            }
        }

        const promise = adminsEntryPoint<DeleteShiftRequest, any>(deleteShiftRequest)
            .then((response) => {
                onSave(response)
                onClose()
                toast.success('Shift Deleted')
            })
            .catch((error) => {
                console.error('Error Deleting Shift:', error)
                toast.error('Failed to Delete Shift')
            })

        return toast.promise(promise, {
            pending: 'Deleting Shift...'
        })
    }

    return (
        <>
            {isDeleteMode ? (
                <Modal title='Delete Shift' open={open} onClose={handleClose}>
                    <Text type='b500'>Are you sure you want to delete this shift?</Text>
                    <ModalFooter>
                        <Row alignmentHorizontal='end'>
                            <Button type='secondary' onClick={handleClose}>No</Button>
                            <Button onClick={handleDelete}>Yes</Button>
                        </Row>
                    </ModalFooter>
                </Modal>
            ) : (
                <Modal title={title} open={open} onClose={handleClose} onSubmitForm={handleSave}>
                <Row >
                    <Input 
                        label='Warehouse'
                        value={location}
                        disabled={true}
                        onChange={(e) => setShift({...shift, warehouse_id: location})}
                    />
                    <Input 
                        inaccessibleAutoFocus
                        label='Shift'
                        value={shift.shift_name}
                        onChange={(e) => setShift({...shift, shift_name: e})}
                        error={!shift.shift_name && displayError}
                        errorMessage={!shift.shift_name ? REQUIRED_INPUT_ERROR_MESSAGE : ''}
                    />
                </Row>
                <Row alignmentHorizontal='center' spacingInset='400 none none none'>
                <Select
                        label='Active'
                        value={shift.active.toString()}
                        onChange={(e) => setShift({...shift, active: e === 'true' ? true : false})}
                    >
                        <SelectOption value='true' label='True'/>
                        <SelectOption value='false' label='False'/>
                    </Select>
                </Row>
                <ModalFooter>
                    <Row alignmentHorizontal='end'>
                        <Button type='secondary' onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button submit={true}>
                            {isCreateMode ? 'Create' : 'Save'}
                        </Button>
                    </Row>
                </ModalFooter>
                </Modal>
            )
            }
        </>
    )
}

export default ShiftModal;