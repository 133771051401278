import { CognitoConfig } from '../api/models/cognito';

export const configureAmplify = (cognitoConfig: CognitoConfig) => {
    const amplify = {
        Auth: {
            region: cognitoConfig.region,
            userPoolId: cognitoConfig.userPoolId,
            userPoolWebClientId: cognitoConfig.userPoolWebClientId,
            oauth: {
                domain: cognitoConfig.oauth.domain,
                redirectSignIn: `${window.location.origin}/`,
                redirectSignOut: `${window.location.origin}/`,
                scope: ['openid', 'aws.cognito.signin.user.admin'],
                responseType: 'code'
            }
        }
    }
    return amplify;
}