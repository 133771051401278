import { APIType, BannerType } from "../types";
import { Tool } from "./tool";

export interface Banner {
    id: number;
    tool: string;
    message: string;
    banner_type: BannerType;
    active: boolean;
}

interface CreateBannerInput {
    tool: string;
    message: string;
    banner_type: BannerType;
    active: boolean;
}

interface DeleteBannerInput {
    id: number;
}

export interface GetAllBannersRequest {
    api_type: APIType;
}

export interface GetAllBannersResponse {
    banners: Banner[];
    tools: Tool[];
}

export interface CreateBannerRequest {
    api_type: APIType;
    api_input: CreateBannerInput;
}

export interface UpdateBannerRequest {
    api_type: APIType;
    api_input: Banner;
}

export interface DeleteBannerRequest {
    api_type: APIType;
    api_input: DeleteBannerInput;
}

export const EMPTY_BANNER: Banner = {
    id: 1,
    tool: 'ALL',
    message: '',
    banner_type: 'info',
    active: false
}

export const EMPTY_GET_ALL_BANNERS_RESPONSE: GetAllBannersResponse = {
    banners: [],
    tools: []
}