import { APIType } from "../types";

export interface AccessLog {
    id: number;
    api_type: string;
    api_input: {[key: string]: any};
    login: string;
    result: string;
    error_code: string;
    created_at: number;
}

export interface GetAllAccessLogsRequest {
    api_type: APIType;
}

export interface GetAllAccessLogsResponse {
    logs: AccessLog[];
}

export const EMPTY_GET_ALL_ACCESS_LOGS_RESPONSE: GetAllAccessLogsResponse = {
    logs: []
}