export enum UserStatus {
    LOADING = 'loading',
    SUCCESS = 'success',
    UNAUTHORIZED = 'unauthorized'
}

export interface UserInfo {
    username: string;
    posixGroup: string[];
    departmentName: string;
    location: string;
    userStatus: UserStatus;
    idToken: string;
}

export const EMPTY_USER_INFO: UserInfo = {
    username: '',
    posixGroup: [''],
    departmentName: '',
    location: '',
    userStatus: UserStatus.LOADING,
    idToken: ''
}