import React, { useState, useEffect, useContext, FC } from 'react';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Select, { SelectOption } from '@amzn/meridian/select';
import Button from '@amzn/meridian/button';
import Input from '@amzn/meridian/input';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import { toast } from 'react-toastify';
import { 
    Process, 
    EMPTY_PROCESS, 
    CreateProcessRequest, 
    UpdateProcessRequest,
    DeleteProcessRequest
} from '../../../api/models/process';
import { adminsEntryPoint } from '../../../api';
import { AuthContext } from '../../../auth/authContext';
import { REQUIRED_INPUT_ERROR_MESSAGE } from '../../../common/constants';

interface ProcessModalProps {
    title: string;
    open: boolean;
    onClose: () => void;
    initialData: Process;
    onSave: (data: Process) => void;
    isCreateMode: boolean;
    isDeleteMode?: boolean;
}

const ProcessModal: FC<ProcessModalProps> = (props) => {
    const { title, open, onClose, initialData, onSave, isCreateMode, isDeleteMode } = props
    const [process, setProcess] = useState<Process>(initialData || EMPTY_PROCESS)
    const [displayError, setDisplayError] = useState<boolean>(false);
    const { location } = useContext(AuthContext)

    useEffect(() => {
        if (initialData) {
            setProcess(initialData)
        }
    }, [initialData])

    const handleClose = () => {
        setProcess(initialData)
        onClose()
        setDisplayError(false);
    }

    const handleSave = () => {
        if (process.process_name === "") {
            setDisplayError(true);
            return;
        }

        if (isCreateMode) {
            const createProcessRequest: CreateProcessRequest = {
                api_type: 'CREATE_PROCESS',
                api_input: {
                    warehouse_id: location,
                    process_name: process.process_name,
                    short_name: process.short_name,
                    department: process.department,
                    function_type: process.function_type,
                    active: process.active
                }
            }

            const promise = adminsEntryPoint<CreateProcessRequest, any>(createProcessRequest)
                .then((response) => {
                    setProcess(EMPTY_PROCESS)
                    onSave(response)
                    onClose()
                    toast.success('Process Created')
                })
                .catch((error) => {
                    console.error('Error Creating Process:', error)
                    toast.error('Faild to Create Process')
                })

            return toast.promise(promise, {
                pending: 'Creating Process...'
            })
        } else {
            const updateProcessRequest: UpdateProcessRequest = {
                api_type: 'UPDATE_PROCESS',
                api_input: {
                    id: process.id,
                    warehouse_id: process.warehouse_id,
                    process_name: process.process_name,
                    short_name: process.short_name,
                    department: process.department,
                    function_type: process.function_type,
                    active: process.active
                }
            }

            const promise = adminsEntryPoint<UpdateProcessRequest, any>(updateProcessRequest)
                .then((response) => {
                    setProcess(EMPTY_PROCESS)
                    onSave(response)
                    onClose()
                    toast.success('Process Updated')
                })
                .catch((error) => {
                    console.error('Error Updating Process:', error)
                    toast.error('Failed to Update Process')
                })

            return toast.promise(promise, {
                pending: 'Updating Process...'
            })
        }
    }

    const handleDelete = () => {
        const deleteProcessRequest: DeleteProcessRequest = {
            api_type: 'DELETE_PROCESS',
            api_input: {
                id: process.id
            }
        }

        const promise = adminsEntryPoint<DeleteProcessRequest, any>(deleteProcessRequest)
            .then((response) => {
                onSave(response)
                onClose()
                toast.success('Process Deleted')
            })
            .catch((error) => {
                console.error('Error Deleting Process:', error)
                toast.error('Failed to Delete Process')
            })

        return toast.promise(promise, {
            pending: 'Deleting Process...'
        })
    }

    return (
        <>
            {isDeleteMode ? (
                <Modal title='Delete Process' open={open} onClose={handleClose}>
                    <Text type='b500'>
                        Are you sure you want to delete <b>{process.process_name}</b>?
                    </Text>
                    <ModalFooter>
                        <Row alignmentHorizontal='end'>
                            <Button type='secondary' onClick={handleClose}>No</Button>
                            <Button onClick={handleDelete}>Yes</Button>
                        </Row>
                    </ModalFooter>
                </Modal>
            ) : (
                <Modal title={title} open={open} onClose={handleClose} onSubmitForm={handleSave}>
                    <Row alignmentHorizontal='center'>
                        <Input 
                            label='Warehouse'
                            value={location}
                            disabled={true}
                            onChange={(e) => setProcess({...process, warehouse_id: location})}
                        />
                        <Input 
                            inaccessibleAutoFocus
                            label='Process Name'
                            value={process.process_name}
                            onChange={(e) => setProcess({...process, process_name: e})}
                            error={!process.process_name && displayError}
                            errorMessage={!process.process_name ? REQUIRED_INPUT_ERROR_MESSAGE : ''}
                        />
                    </Row>
                    <Row alignmentHorizontal='center' spacingInset='400'>
                        <Input 
                            label='Short Name'
                            value={process.short_name}
                            onChange={(e) => setProcess({...process, short_name: e})}
                        />
                        <Input 
                            label='Department'
                            value={process.department}
                            onChange={(e) => setProcess({...process, department: e})}
                        />
                    </Row>
                    <Row alignmentHorizontal='center' >
                        <Select
                            label='Function-Type'
                            value={process.function_type}
                            onChange={(e) => setProcess({...process, function_type: e})}
                            width={150}
                        >
                            <SelectOption value='Indirect' label='Indirect'/>
                            <SelectOption value='Direct' label='Direct'/>
                        </Select>
                        <Select
                            label='Active'
                            value={process.active.toString()}
                            onChange={(e) => setProcess({...process, active: e === 'true' ? true : false})}
                        >
                            <SelectOption value='true' label='True'/>
                            <SelectOption value='false' label='False'/>
                        </Select>
                    </Row>
                    <ModalFooter>
                        <Row alignmentHorizontal='end'>
                            <Button type='secondary' onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button submit={true}>
                                {isCreateMode ? 'Create' : 'Save'}
                            </Button>
                        </Row>
                    </ModalFooter>
                </Modal>
            )}
        </>
    )
}

export default ProcessModal;