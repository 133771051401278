import React, { createContext, useEffect, useState } from 'react';
import { configureAmplify } from './authConfigs';
import { Auth } from '@aws-amplify/auth';
import { UserInfo, UserStatus } from '../api/models/userInfo'
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { POSIX_GROUP_KEY, DEPT_NAME, LOCATION } from '../common/constants';
import { JSONParse } from '../common/utilities';
import Loader from '@amzn/meridian/loader';
import axios from 'axios';

type AuthWrapperProps = {
    children: React.ReactNode
}

const AuthorizationWrapper = ({ children }: AuthWrapperProps) => {
    const [userData, setUserData] = useState<UserInfo>({
        userStatus: process.env.NODE_ENV === 'development'
            ? UserStatus.SUCCESS
            : UserStatus.LOADING
    } as UserInfo);

    useEffect(() => {
        fetch('/settings.json')
            .then(resp => {
                return resp.json();
            })
            .then(data => {
                Auth.configure(configureAmplify(data))
                Auth.currentSession()
                    .then((session: CognitoUserSession) => {
                        let posixGroups = session.getIdToken().payload[POSIX_GROUP_KEY];
                        let posixArray: string[] = JSONParse(posixGroups, []);
                        setUserData({
                            username: session.getIdToken().payload['identities'][0].userId,
                            posixGroup: posixArray,
                            departmentName: session.getIdToken().payload[DEPT_NAME],
                            location: session.getIdToken().payload[LOCATION].split("-")[0].trim(),
                            userStatus: UserStatus.SUCCESS,
                            idToken: session.getIdToken().getJwtToken()
                        });
                    }).catch(() => {
                        Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
                    })
            }).catch(e => {
                console.error(e);
            });
            return () => {
                Auth.signOut()
            }
    }, [])
    axios.defaults.headers.common['Authorization'] = userData.idToken

    if (userData?.userStatus === UserStatus.LOADING) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Loader />
            </div>
        )
    } else {
        return <AuthContext.Provider value={userData}>{children}</AuthContext.Provider>
    }
}

const initialData: UserInfo = { userStatus: UserStatus.LOADING } as UserInfo;
export const AuthContext = createContext(initialData);
export default AuthorizationWrapper;